{
    const shortNavEL = document.getElementById('shortNav')
    const barsBtnEl = document.getElementById('barsBtn')
    const iconBarsEl = document.getElementById('iconBars')
    const iconCloseEl = document.getElementById('iconClose')
    const navSideBlockEl = document.getElementById('navSideBlock')
    const linkEls = document.querySelectorAll('#navSideBlock .link')
    const mainEl = document.getElementsByTagName('html')[0]

    const shortNavClass = 'short-navbar--open'
    const faClass = 'fa--hidden'
    const navSideClass = 'short-navbar__bottom-block--show'
    const mainClass = 'wrapper--shadow'

    barsBtnEl.addEventListener('click', () => {
        const isShow = navSideBlockEl.classList.contains(navSideClass)
        if (isShow) {
            shortNavEL.classList.remove(shortNavClass)
            iconBarsEl.classList.remove(faClass)
            iconCloseEl.classList.add(faClass)
            navSideBlockEl.classList.remove(navSideClass)
            mainEl.classList.remove(mainClass)
        } else {
            shortNavEL.classList.add(shortNavClass)
            iconBarsEl.classList.add(faClass)
            iconCloseEl.classList.remove(faClass)
            navSideBlockEl.classList.add(navSideClass)
            mainEl.classList.add(mainClass)
        }
    })

    navSideBlockEl.addEventListener('click', () => barsBtnEl.click())
}